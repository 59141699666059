<template>
  <div class="eyeliner">
    <Hero :heading-text="'Eyeliner'" :heading-subtitle="'OČI KAO UNIKATAN DEO NAŠEG LICA DEFINIŠU NAŠ IZGLED, A NJIHOVU LEPOTU NIŠTA NE ISTIČE BOLJE OD AJLAJNERA'" :cta-text="'ZAKAŽITE TERMIN'" background-url="maxresdefault.jpg"  background-url-mobile="UltimatePenEyeliner.jpg"></Hero>
    <HeadlineCopy src1="eyeliner.jpg" src2="royalartist.png" :heading-text="'Prirodan, <br>nežan izgled'" :heading-subtitle="'Zaboravite na iscrtavanje ajlajnera. <br>Trajnom šminkom možete imati perfektan ajlajner svakodnevno. <br>Za dame koje žele prirodan, nežan, sofisticiran<br/> ali ništa manje neodoljiv izgled - ovo je pravo rešenje.'" :cta-text="'Zakažite termin'"></HeadlineCopy>
    <SingleTextComponent :heading-text="'KONTURISANJE - NAGLAŠAVANJE IVICA - INTEZIVNE BOJE'" background-url="eyeliner-4.jpg"></SingleTextComponent>
    <HeadlineCopy src1="BeautyTips.jpg" :heading-text="'Intenzivan <br>izgled eyelinera'" :heading-subtitle="'Za one dame koje vole još upečatljiviji, efektniji i naglašeniji izgled, preporučujemo intenzivan, senčeni ajlajner. Prepustite se rukama stručnjaka i budite perfektno našminkane svaki dan!'"></HeadlineCopy>
    <Footer></Footer> 
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import HeadlineCopy from '@/components/HeadlineCopy.vue'
import SingleTextComponent from '@/components/SingleTextComponent.vue'
import Footer from '@/components/Footer.vue'



export default {
  name: 'Eyeliner',
  components: {
    Hero,
    HeadlineCopy,
    SingleTextComponent,
    Footer
  }
}
</script>